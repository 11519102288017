<script>

import TEditTemplate from "@/components/pages/Edit";
export default {
  _config:{
    route:{
      path:"add",
      meta:{
        title:"新增"
      }
    }
  },
  components: {TEditTemplate},
  data() {
    return {
      form: {
        school_id: '',
        set_type: '',
        warning_minite: '',
        push_mode: '',
        push_id: '',
        push_id_1: '',
        push_id_2: ''
      },
      rules: {
        school_id: [
          {required: true, message: '请选择校区', trigger: 'change'}
        ],
        set_type: [
          {required: true, message: '请选择类型', trigger: 'change'}
        ],
        warning_minite: [
          {required: true, message: '请输入时间', trigger: 'change'},
          {pattern: /^(?:[1-9]|[12][0-9]|30)$/, message: '仅可输入1-30之间的整数', trigger: 'change'}
        ],
        push_mode: [
          {required: true, message: '请选择推送方式', trigger: 'change'}
        ],
        push_id_1: [
          {required: true, message: '请选择群组', trigger: 'change'}
        ],
        push_id_2: [
          {required: true, message: '请输入推送人员姓名', trigger: 'change'}
        ]
      },
      options: [[], [], []]
    }
  },
  methods: {
    save(form) {
      this.$refs[form].validate((valid, obj) => {
        if (valid) {
          if (this.form.push_mode === '1')
            this.form.push_id = this.form.push_id_1
          if (this.form.push_mode === '2')
            this.form.push_id = this.form.push_id_2
          this.$_axios2.post('api/attendance/attendance-warning/set-minite', this.form, {logic: 1}).then(res => {
            this.$message.success('添加成功')
            this.$router.back()
            this.$store.commit('setPage', 1)
          })
        }
      })
    },
    change(val) {
      this.form.push_id_1 = ''
      this.form.push_id_2 = ''
      this.options[2] = []
      this.$_axios2.get('api/attendance/group/linkage?school_id=' + val).then(res => {
        this.options[1] = res.data.data
        this.$forceUpdate()
      })
    },
    remoteMethod(val) {
      this.$_axios2.get('api/common/screen/search-teacher', {params: {keyword: val, school_id: this.options[0].filter(item => item.id === this.form.school_id)[0]?.id, type: 0}}).then(res => {
        this.options[2] = res.data.data;
        this.$forceUpdate()
      })
    },
    change1() {
      this.$forceUpdate()
      setTimeout(() => {
        this.$refs.form.validateField("push_id_1");
        this.$refs.form.validateField("push_id_2");
      }, 200)
    }
  },
  mounted() {
    this.$_axios.get('site/school').then(res => {
      this.options[0] = res.data.data
      this.$forceUpdate()
    })
  }
}
</script>

<template>
  <t-edit-template style="box-sizing: border-box;padding: 48px" confirm-text="保存" cancel-text="取消" @@confim="save('form')" @cancel="$router.back()" :cancel-remind="false">
    <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="150px">
      <el-form-item label="校区" prop="school_id" style="width: 500px">
        <el-select v-model='form.school_id' placeholder='请选择校区' @change="change">
          <el-option v-for='(item,index) in options[0]' :key="index" :label='item.school_name' :value='item.id'></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="类型" prop="set_type" style="width: 500px">
        <el-radio-group v-model="form.set_type">
          <el-radio label="1">迟到</el-radio>
          <el-radio label="2">未打卡</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="时间" prop="warning_minite" style="width: 500px">
        <div style="display: flex">
          上课后
          <el-input v-model="form.warning_minite" placeholder="请输入时间"
                    maxlength="9"
                    style="width: 120px !important;margin: 0 4px"
                    @input="(v) => form.warning_minite = String(parseInt(v.replace(/[^0-9]/g, ''), 10) || '')"></el-input>
          分钟
        </div>
      </el-form-item>

      <el-form-item label="推送方式" prop="push_mode" style="width: 500px">
        <el-radio-group v-model="form.push_mode">
          <el-radio label="1">群发</el-radio>
          <el-radio label="2">单人</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="form.push_mode === '1'" label="选择群组" prop="push_id_1" style="width: 500px">
        <el-select v-model='form.push_id_1' :disabled="!form.school_id" placeholder='请选择群组' @change="change1">
          <el-option v-for='(item,key) in options[1]' :key="key" :label='item.name' :value='item.id'></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="form.push_mode === '2'" label="推送人员姓名" prop="push_id_2" style="width: 500px">
        <el-select
            v-model="form.push_id_2"
            :disabled="!form.school_id"
            :remote-method="remoteMethod"
            filterable
            placeholder="请输入推送人员姓名"
            remote
            reserve-keyword @change="change1">
          <el-option
              v-for="item in options[2]"
              :key="item.id"
              :label="`${item.clerk_name}(${item.mobile})`"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

  </t-edit-template>
</template>

<style lang="scss" scoped>

</style>
